import React, { useState, useEffect, useContext } from 'react';
import OnceFooter from "./common/Footer/OnceFooter";
import Menu from "./common/Menu/Menu";
import Services from "./HomeSection/Services";
import SliderHome from "./HomeSection/SliderHome";
import TopCtaArea from "./HomeSection/TopCtaArea";
import { useParams, useLocation } from 'react-router-dom';
import api from './../utils/api';
import { AppContext } from "./context/ContextProvider";
import { Button } from 'react-bootstrap';
import BookingPriceSummary from "./Booking/BookingPriceSummary";
import ReactGA from 'react-ga4';

export default function Home() {
  const { id, is_booking, s_date, e_date } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const qStartDate = searchParams.get('s_date');
  const qEndDate = searchParams.get('e_date');
  const qBookingData = searchParams.get('book_data');

  const [currentRoom, setCurrentRoom] = useState({})
  const { hotel } = currentRoom
  const { handleSetRoomSelected, currentHotel, handleSetCurrentHotel,
    handleSetBookStartDate,
    handleSetBookEndDate,
    handleSetBookingData,
  } = useContext(AppContext);

  const [forceShowBookingModal, setForceShowBookingModal] = useState(false)

  const fetchHotelData = (hotelId, rData) => {
    api.request(`hotels/${hotelId}`, (data) => {
      handleSetCurrentHotel(data)

      ReactGA.send({ hitType: "pageview", page: `once/${id}`, title: `Room - ${data?.name_th} : ${rData?.name_th}` });

      // ReactGA.event({
      //   category: "Room",
      //   action: "View",
      //   label: `page - ${data?.name_th} : ${rData?.name_th}`,
      // });
    }, {})
  }

  const fetchRoomData = () => {
    api.request(`rooms/${id}`, (data) => {
      if (!data) {
        alert("ลิงค์ไม่ถูกต้อง !")
        window.location.replace("https://www.onceinmemory.com");
        return
      }

      fetchHotelData(data.hotel_id, data)
      setCurrentRoom(data)
      handleSetRoomSelected(data)

      if (!!qBookingData && !!qStartDate && !!qEndDate) {
        const bookingData = JSON.parse(qBookingData)
        const startDate = new Date(qStartDate)
        const endDate = new Date(qEndDate)

        handleSetBookStartDate(startDate)
        handleSetBookEndDate(endDate)
        handleSetBookingData(bookingData)
        setForceShowBookingModal(true)
      }
    }, {})
  }

  useEffect(() => {
    fetchRoomData()
  }, [id])

  return (
    <>
      <Menu header_style_2={true} />
      <TopCtaArea />
      {!!currentHotel?.hotel_medias?.length && <SliderHome />}
      <Services />
      <div id="map" >
        {/* todo custom map marker */}
        {!!hotel?.map_file_path && <img src={hotel?.map_file_path} alt="img" />}
        {!!hotel?.map_search_name && <div className="tp-ct-map">
          <iframe
            title="map"
            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAb7JNGOXId67KUS1eYlGXHgHXvVTczcgU&q=${hotel?.map_search_name}`}
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>}
      </div>
      <OnceFooter />
      <BookingPriceSummary forceShowBookingModal={forceShowBookingModal} />
    </>
  );
}
