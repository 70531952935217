import React, { useState, useEffect, useContext, forwardRef } from 'react';
import OnceFooter from "../common/Footer/OnceFooter";
import Menu from "../common/Menu/Menu";
import { useParams } from 'react-router-dom';
import api from './../../utils/api';
import { Link } from "react-router-dom";
import { AppContext } from "./../context/ContextProvider";
import SliderHome from "./../HomeSection/SliderHome";
import Services from "./../HomeSection/Services";
import Iframe from 'react-iframe'
import { Row, Col, Button } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import _ from "lodash";
import VideoViewerModal from "./components/VideoViewerModal";
import { numberFormat } from './../../utils/functions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import 'moment/locale/th'
import Switch from "react-switch";
import ReactGA from 'react-ga4';

const Rooms = () => {
  const { code } = useParams();

  ReactGA.send({ hitType: "pageview", page: code, title: `${code} hotel index` });

  const [hotel, setHotels] = useState({})
  const [rooms, setRooms] = useState([])
  const [sortingRooms, setSortingRooms] = useState(rooms)
  const { handleSetCurrentHotel } = useContext(AppContext);
  const [imgForPreview, setImgForPreview] = useState([])
  const [photoIndex, setPhotoIndex] = useState(0)

  const [show360Modal, setShow360Modal] = useState(false)

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [roomIdsInbookings, setRoomIdsInBookings] = useState([]);
  const [roomIdsInBlockdays, setRoomIdsInBlockdays] = useState([]);
  const [hideRooms, setHideRooms] = useState(false);

  const [campingType, setCampingType] = useState([]);
  const [walkingBalance, setWalkingBalance] = useState(0);

  const fetchHotel = () => {
    api.request(`hotels/findone`, (data) => {
      if (!data) {
        alert("ลิงค์ไม่ถูกต้อง !")
        window.location.replace("https://www.onceinmemory.com");
        return
      }

      setHotels(data)
      handleSetCurrentHotel(data)
      fetchRooms(data.id)

      if (data?.allow_walkin) {
        fetchCampingTypes(data.id)
      }
    }, {
      enabled: true,
      code: code
    })
  }

  const fetchRooms = (hotel_id) => {
    api.request(`rooms/all`, (res) => {
      setRooms(res || [])
      setSortingRooms(res || [])
    }, {
      enabled: true,
      hotel_id: hotel_id
    })
  }

  const fetchฺBookingFromDate = () => {
    if (!startDate || !endDate || !hotel?.id) {
      setRoomIdsInBookings([])
      return
    }

    api.request('bookings/all-check-room', (data) => {
      if (data && data.length) {
        setRoomIdsInBookings(data.map((item) => { return item.room_id }))
      } else {
        setRoomIdsInBookings([])
      }
    }, {
      hotel_id: hotel.id,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).subtract(1, 'days').format('YYYY-MM-DD'),
      ignore_walkin: true
    })
  }

  const fetchฺBlockdayFromDate = () => {
    if (!startDate || !endDate || !hotel?.id) {
      setRoomIdsInBlockdays([])
      return
    }

    api.request('bookings/all-check-room-blockday', (data) => {
      if (data && data.length) {
        setRoomIdsInBlockdays(data.map((item) => { return item.room_id }))
      } else {
        setRoomIdsInBlockdays([])
      }
    }, {
      hotel_id: hotel.id,
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).subtract(1, 'days').format('YYYY-MM-DD')
    })
  }

  const fetchCampingTypes = (hotel_id) => {
    api.request(`camp-types/all`, (res) => {
      setCampingType(res || [])
    }, {
      enabled: true,
      hotel_id: hotel_id
    })
  }

  // const isNotAvai = _.includes(roomIdsInbookings, item.id)

  useEffect(() => {
    if (!code) {
      return
    }
    fetchHotel()
  }, [code])

  useEffect(() => {
    fetchฺBookingFromDate()
    fetchฺBlockdayFromDate()
  }, [startDate, endDate])

  useEffect(() => {
    if (!rooms?.length) {
      return
    }

    if ((!roomIdsInbookings || !roomIdsInbookings.length) && (!roomIdsInBlockdays || !roomIdsInBlockdays.length)) {
      setSortingRooms(rooms)
    } else {
      const ignoreRooms = _.union((roomIdsInbookings || []), (roomIdsInBlockdays || []))
      const sorting = rooms.map((r) => { return { ...r, is_sort: (_.includes(ignoreRooms, r.id) ? 1 : 0) } })
      setSortingRooms(_.orderBy(sorting, ['is_sort'], ['asc']))
    }
  }, [roomIdsInbookings, roomIdsInBlockdays])

  const cover = hotel?.hotel_medias?.length ? hotel?.hotel_medias[0].file_path : ''

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="px-3 py-1 custom-dateinput-search" onClick={onClick} ref={ref}>
      <div className="d-flex align-items-center">
        <div className="checktime-info ps-2">
          <p className="mb-0">เช็คอิน</p>
          <div className="display-date">{!!startDate ? moment(startDate).format('DD/MM/YYYY') : ''} </div>
        </div>
        <div className="mx-3" style={{ height: 40, width: 1, backgroundColor: '#000000' }}></div>
        <div className="checktime-info">
          <p className="mb-0">เช็คเอาท์</p>
          <div className="display-date">{!!endDate ? moment(endDate).format('DD/MM/YYYY') : ''} </div>
        </div>
      </div>
    </button>
  ));

  return (
    <>
      <Menu header_style_2={true} />
      <section
        className="breadcrumb__area include-bg breadcrumb__overlay portfolio_banner"
        style={{
          backgroundImage: `url(${cover})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content text-center p-relative z-index-1">
                <h3 className="breadcrumb__title">{hotel?.name_th}</h3>
                <div className="breadcrumb__list">
                  <span>{hotel?.short_slogan_th}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Row>
        <Col xs={12} md={6}>
          <div className="pt-50 d-flex justify-content-center align-items-center">
            <div className="text-center">
              <DatePicker
                selected={startDate}
                onChange={(dates) => {
                  const [start, end] = dates;
                  setStartDate(start);
                  setEndDate(end);
                }}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                dateFormat="dd/MM/yyyy"
                minDate={new Date()}
                isClearable
                customInput={<ExampleCustomInput />}
              />
            </div>
            <div className="hide-rooms ms-3">
              <p className="mb-0">ซ่อนห้องไม่ว่าง</p>
              <Switch onChange={(e) => setHideRooms(e)} checked={hideRooms} offColor={'#B0B0B0'} onColor={'#000000'} />
            </div>
          </div>

        </Col>
        <Col xs={12} md={6}>
          {!!hotel['360_link'] && <div className="text-center d-flex justify-content-center align-items-center pt-50">
            <div className="me-2">
              <img className="icon-rotate-x" style={{ width: 50, height: 50 }} src={"/assets/img/360/icon_1.png"} alt="img" />
            </div>
            <div className="tp-btn-border rounded" onClick={() => setShow360Modal(true)}>
              ดูแบบ 360°
              <span>
                <svg
                  width="22"
                  height="8"
                  viewBox="0 0 22 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                    fill="currentColor"
                  />
                </svg>
                <svg
                  width="22"
                  height="8"
                  viewBox="0 0 22 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>}
        </Col>
      </Row>

      {sortingRooms?.length > 0 && <div className="project-page-list pt-50 pb-50">
        <div className="container">
          <div className="row">
            {/* >> กางเต๊นท์ */}
            {(!!hotel?.allow_walkin && !hotel?.walkin_booking_disabled) && <div className="col-lg-4 col-md-6 col-12">
              <div className="pj-list-item mb-50">
                <div className="image-thumb-with-preview mb-10 rounded">
                  <img src={"/assets/img/booking/camping.jpg"} alt="camping" />
                  <span className="price-span rounded">฿{numberFormat(hotel?.walk_in_total_price, true)} / ท่าน</span>
                </div>
                {/* {(!!startDate && !!endDate) && <div>
                  {walkingBalance > 0 && <span className="mb-0 text-black"><i className="far fa-check-square"></i> เหลือพื้นที่ให้เช่าสำหรับ {numberFormat(walkingBalance, true)} ท่าน </span>}
                  {walkingBalance === 0 && <span className="mb-0 text-danger"><i className="far fa-window-close"></i> พื้นที่เต็มในวันที่ค้นหา </span>}
                </div>} */}
                {campingType?.length > 0 && <div className="py-2">
                  {campingType?.map((h, i) => {
                    return (
                      <div key={`s-camp-type-${i + 1}`} className="d-inline-block ms-2">
                        <i className="fal fa-check text-success me-2"></i>
                        <span className="mb-0">{h.name_th}</span>
                      </div>
                    )
                  })}
                </div>}
                <span>
                  <a href="#">ค่าบริการเช่าพื้นที่ ผู้ใหญ่ท่านละ {numberFormat(hotel?.walk_in_total_price, true)} บาท, เด็กอายุ 6 - 12 ปี ท่านละ {numberFormat(hotel?.walk_in_child_total_price, true)} บาท, เด็กอายุต่ำกว่า 6 ปี ไม่คิดค่าบริการ</a>
                </span>
                <h4 className="pj-list__title">
                  <Link className="py-2" to={`/once/camping-booking/${hotel?.id}`} onClick={() => {
                    ReactGA.event({
                      category: "Booking Button",
                      action: `${hotel?.code}_camping_booking_click`,
                      label: `${hotel?.name_th} - Camping Booking Button`,
                    });
                  }}>CAMPING
                    <span className="ms-2 text-success border border-success rounded p-2"><i className="fal fa-calendar" style={{ fontSize: 18 }}></i> จองพื้นที่</span>
                  </Link>
                </h4>
              </div>
            </div>}
            {/* << กางเต๊นท์ */}

            {sortingRooms.map((item) => {
              const img = item?.room_medias?.length ? item?.room_medias[0].file_path : ''
              const roomImgForSlide = _.map(item?.room_medias || [], 'file_path')
              const isNotAvai = _.includes(roomIdsInbookings, item.id) || _.includes(roomIdsInBlockdays, item.id)

              const priceStr = (Number(item?.display_min_price) > 0 && Number(item?.display_max_price) > 0) ? `฿${numberFormat(item?.display_min_price, true)} - ฿${numberFormat(item?.display_max_price, true)}` : `฿${numberFormat(item?.total_price, true)}`

              if (isNotAvai && hideRooms) {
                return <></>
              }

              return (
                <div key={item.id} className="col-lg-4 col-md-6 col-12">
                  <div className="pj-list-item mb-50">
                    <div className="image-thumb-with-preview mb-10 rounded">
                      <img src={img} alt={item.name_th} />
                      <span onClick={() => setImgForPreview(roomImgForSlide)} className="image-preview-span"><i className="fal fa-images"></i></span>
                      {!isNotAvai && <span className="price-span rounded">{priceStr}</span>}
                      {isNotAvai && <span className="not-avar-span rounded">ห้องพักนี้ไม่ว่างในวันที่เลือก</span>}
                      {/* {(!!startDate && !!endDate) && !isNotAvai && <span className="price-span">{priceStr}</span>}
                      {(!!startDate && !!endDate) && isNotAvai && <span className="not-avar-span">ห้องพักนี้ไม่ว่างในวันที่เลือก</span>}
                      {(!startDate || !endDate) && <span className="price-span">เลือกวันที่เพื่อดูราคา</span>} */}
                    </div>
                    <div>
                      <span className="mb-0"><i className="fal fa-user"></i>  {item.guest_qty} ท่าน</span>
                      {!!item.bath_room_qty && item.bath_room_qty > 0 && <span className="ms-2 mb-0"><i className="fal fa-shower"></i>  {item.bath_room_qty}</span>}
                      {!!item.bed_room_qty && item.bed_room_qty > 0 && <span className="ms-2 mb-0"><i className="fal fa-bed"></i>  {item.bed_room_qty}</span>}
                    </div>
                    {item?.facilities?.length > 0 && <div className="py-2">
                      {item?.facilities?.map((h, i) => {
                        return (
                          <div key={`s-room-fac-${i + 1}`} className="d-inline-block ms-2">
                            <i className="fal fa-check text-success me-2"></i>
                            <span className="mb-0">{h.name_th}</span>
                          </div>
                        )
                      })}
                    </div>}
                    <span>
                      <a href="#">{item.short_info_th}</a>
                    </span>
                    <h4 className="pj-list__title">
                      <Link className="py-2" to={`/once/${item.id}`} onClick={() => {
                        ReactGA.event({
                          category: "Booking Button",
                          action: `${hotel?.code}_${item.room_no}_booking_click`,
                          label: `${hotel?.name_th} - Booking Button with ${item.name_th}`,
                        });
                      }}>{item.name_th}
                        <span className="ms-2 text-success border border-success rounded p-2"><i className="fal fa-calendar" style={{ fontSize: 18 }}></i> จองห้องพัก</span>
                      </Link>
                    </h4>
                  </div>
                </div>
              )
            })}

            {/* >> เหมาลาน */}
            {code === 'newzealand-lopburi' && <div className="col-lg-4 col-md-6 col-12">
              <div className="pj-list-item mb-50">
                <div className="image-thumb-with-preview mb-10 rounded">
                  <img src={"/assets/img/booking/closezone_cover.jpg"} alt="camping" />
                  <span className="price-span rounded">ราคาพิเศษ!</span>
                </div>
                <div className="d-inline-block ms-2">
                  <i className="fal fa-check text-success me-2"></i>
                  <span className="mb-0">รถบ้าน 3 คัน พักได้ 10 ท่าน</span>
                </div>
                <div className="d-inline-block ms-2">
                  <i className="fal fa-check text-success me-2"></i>
                  <span className="mb-0">สนามลานกางเต็นท์ พักได้ 30 ท่าน</span>
                </div>
                <div className="d-inline-block ms-2">
                  <i className="fal fa-check text-success me-2"></i>
                  <span className="mb-0">ห้องน้ำรถบ้าน 3 ห้อง, ห้องน้ำส่วนกลาง 2 ห้อง</span>
                </div>
                <div className="d-inline-block ms-2">
                  <i className="fal fa-check text-success me-2"></i>
                  <span className="mb-0">จุดเชื่อมไฟ, น้ำ, wifi ทั่วบริเวณลาน</span>
                </div>
                <div className="d-inline-block ms-2">
                  <i className="fal fa-check text-success me-2"></i>
                  <span className="mb-0">จอดรถได้ 10 คัน</span>
                </div>
                <div className="d-inline-block ms-2">
                  <i className="fal fa-check text-success me-2"></i>
                  <span className="mb-0">อื่นๆ</span>
                </div>
                <span>
                  <a href="#">
                    <p> "ลานของเรา อยู่ติดขอบเขื่อน สามารถเดินข้ามไปเล่นน้ำหรือนั่งชมวิวได้เลย" </p>
                    เหมาลาน-ปิดรีสอร์ท (Check in 15:00 - Check Out 12:00)
                    <p className="text-danger">โปรโมชั่นลดราคาพิเศษถึงสิ้นเดือนนี้!!</p>
                  </a>
                  {/* <a href="#">
                    <p> "ลานของเรา อยู่ติดขอบเขื่อน สามารถเดินข้ามไปเล่นน้ำหรือนั่งชมวิวได้เลย" </p>
                    เหมาลาน-ปิดรีสอร์ท (Check in 15:00 - Check Out 12:00)
                    <div> ราคาปกติ 15,000 - 18,000 บาท (โปรลดราคาพิเศษถึงสิ้นเดือนนี้!!)  </div>
                    <div>- วันธรรมดา (จันทร์ - พฤหัสฯ) ราคาเหมา 5,900฿</div>
                    <div>- วันศุกร์ - เสาร์ และวันหยุดฯ ราคาเหมา 8,900฿</div>
                  </a> */}
                </span>
                <h4 className="pj-list__title">
                  <Link className="py-2" to={`https://line.me/ti/p/RhLg0PDWf6`} onClick={() => {
                    ReactGA.event({
                      category: "Booking Button",
                      action: "newzealand-lopburi_closezone_booking_click",
                      label: `${hotel?.name_th} - Close All Button`,
                    });
                  }}>เหมาลาน
                    <span className="ms-2 text-success border border-success rounded p-2"><i className="fal fa-calendar" style={{ fontSize: 18 }}></i> จองพื้นที่</span>
                  </Link>
                </h4>
              </div>
            </div>}
            {/* << เหมาลาน */}
          </div>
        </div>
      </div>}

      {/* <div className="p-4">
        <Iframe url="https://littlekyotoayutthya-mainmenu.360diamondoncrown.com"
          width="100%"
          height="600px"
          id=""
          className=""
          display="block"
          position="relative" />
      </div> */}

      {!!hotel?.hotel_medias?.length && <SliderHome />}
      <Services />
      <div id="map" >
        {/* todo custom map marker */}
        {!!hotel?.map_file_path && <img src={hotel?.map_file_path} alt="img" />}
        {!!hotel?.map_search_name && <div className="tp-ct-map">
          <iframe
            title="map"
            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAb7JNGOXId67KUS1eYlGXHgHXvVTczcgU&q=${hotel?.map_search_name}`}
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>}
      </div>

      <OnceFooter />

      {(!!imgForPreview && !!imgForPreview.length) && (
        <Lightbox
          mainSrc={imgForPreview[photoIndex]}
          nextSrc={imgForPreview[(photoIndex + 1) % imgForPreview.length]}
          prevSrc={imgForPreview[(photoIndex + imgForPreview.length - 1) % imgForPreview.length]}
          onCloseRequest={() => {
            setImgForPreview([])
            setPhotoIndex(0)
          }}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + imgForPreview.length - 1) % imgForPreview.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imgForPreview.length)
          }
        />
      )}
      <VideoViewerModal
        show={show360Modal}
        onHide={() => setShow360Modal(false)}
        uri={hotel['360_link'] || null}
        name={hotel?.name_th}
      />
    </>
  );
};

export default Rooms;
