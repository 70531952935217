import React, { useState, createContext } from "react";

export const AppContext = createContext();

const ContextProvider = ({ children }) => {
  const [isEnter, setIsEnter] = useState(false);
  const [roomSelected, setRoomSelected] = useState({});
  const [currentHotel, setCurrentHotel] = useState({});
  const [bookStartDate, setBookStartDate] = useState(null);
  const [bookEndDate, setBookEndDate] = useState(null);
  const [bookingData, setBookingData] = useState([]);

  // handle mouse enter
  const handleMouseEnter = () => {
    setIsEnter(true);
  };
  // handle leave
  const handleMouseLeave = () => {
    setIsEnter(false);
  };

  const handleSetRoomSelected = (room) => {
    setRoomSelected(room);
  };

  const handleSetCurrentHotel = (hotel) => {
    setCurrentHotel(hotel);
  };

  const handleSetBookStartDate = (s) => {
    setBookStartDate(s);
  };

  const handleSetBookEndDate = (e) => {
    setBookEndDate(e);
  };

  const handleSetBookingData = (book) => {
    setBookingData(book)
  };

  const setDummyToken = (_token) => {
    localStorage.setItem('booking_overview_token_dummy', _token);
  };
  const getDummyToken = (_token) => {
    return localStorage.getItem('booking_overview_token_dummy');
  };

  const values = {
    isEnter,
    handleMouseEnter,
    handleMouseLeave,
    roomSelected,
    handleSetRoomSelected,
    currentHotel,
    handleSetCurrentHotel,
    bookStartDate,
    handleSetBookStartDate,
    bookEndDate,
    handleSetBookEndDate,
    bookingData,
    handleSetBookingData,
    setDummyToken,
    getDummyToken
  };
  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export default ContextProvider;
