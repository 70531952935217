import AppNavigation from "./app-navigation/AppNavigation";
import ReactGA from 'react-ga4';

function App() {
  ReactGA.initialize('G-9LC1ZVM22S');

  return <AppNavigation />;
}

export default App;
